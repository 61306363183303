import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Slide,
  Stack,
  SvgIcon,
  Switch,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import axios from "axios";
import Cookies from "js-cookie";
import Loading from "./Loding";
import UserForm from "./UserForm";
import UserCreationForm from "./UserCreationForm";
import GTM from "../util/gtm";

interface User {
  expireTime: string;
  phone: string;
  referralId: string;
  lastPurchaseTime: string;
  totalQuota: string;
  remainingQuota: string;
  email: string;
  key: string;
  refId: string;
}

const isLocal = window.location.href.indexOf("localhost") > -1;

const User = () => {
  const [hasUser, setHasUser] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [email, setEmail] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<User>({
    expireTime: "",
    phone: "",
    referralId: "",
    lastPurchaseTime: "",
    totalQuota: "",
    remainingQuota: "",
    email: "",
    key: "",
    refId: "",
  });
  const [refData, setRefData] = useState({ success: false });

  const handleGoogleSignInCallback = async () => {
    const code = new URLSearchParams(window.location.search).get("code");
    const url = isLocal
      ? "http://127.0.0.1:5001/ds-excel-app/us-central1/getSignedInUser"
      : "https://getsignedinuser-3xry7nzu2q-uc.a.run.app";
    console.log(code);
    if (code) {
      fetch(url, {
        method: "POST",
        body: JSON.stringify({ code, isLocal }),
      })
        .then((result) => {
          return result.json();
        })
        .then((data) => {
          if (data.email && data.jwt) {
            Cookies.set("jwt", data.jwt, { expires: 1 / 24 });
            GTM.trackGoogleSignInComplete();
            window.location.href = window.location.href.split("?")[0];
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  const handleGoogleSignInClick = async () => {
    setIsLoading(true);
    GTM.trackGoogleSignInClick();
    const url = isLocal
      ? "http://127.0.0.1:5001/ds-excel-app/us-central1/getSignInUrl"
      : "https://getsigninurl-3xry7nzu2q-uc.a.run.app";
    fetch(url, {
      method: "POST",
      body: JSON.stringify({ isLocal }),
    })
      .then((result) => {
        return result.json();
      })
      .then((data) => {
        setIsLoading(false);
        window.location.href = data.signInUrl;
      })
      .catch((error) => {
        setIsLoading(false);
        console.log(error);
      });
  };

  const setAuthentication = async () => {
    const jwt = Cookies.get("jwt");

    if (jwt) {
      const url = isLocal
        ? "http://127.0.0.1:5001/ds-excel-app/us-central1/users"
        : "https://users-3xry7nzu2q-uc.a.run.app";
      fetch(url, {
        method: "POST",
        body: JSON.stringify({ isLocal, action: "GET_USER", jwt }),
      })
        .then((result) => {
          return result.json();
        })
        .then((data) => {
          if (data.email) {
            setAuthenticated(true);
            setEmail(data.email);
            if (
              data.userData &&
              data.userData.success &&
              data.userData.data &&
              data.userData.data.length > 0
            ) {
              setHasUser(true);
              setUser(data.userData.data[0]);
              setRefData(data.refData);
            }
          } else {
            Cookies.remove("jwt");
          }
        })
        .catch((error) => {
          console.log(error);
        });
    }
  };

  useEffect(() => {
    const currentCode = new URLSearchParams(window.location.search).get("code");
    if (currentCode) {
      handleGoogleSignInCallback();
    } else if (Cookies.get("jwt")) {
      setAuthentication();
    }
    setTimeout(
      () => {
        setIsLoading(false);
      },
      Cookies.get("jwt") || currentCode ? 3500 : 200
    );
  }, []);

  return (
    <Container>
      <div style={{ display: "none" }}>
        <h1>Ceylon DS - My Account</h1>
        <h2>Sign In, Sign Up, Renew and Upgrade</h2>
      </div>
      <Box mt={3} mb={3}>
        <Typography variant="overline" color="textSecondary">
          CEYLON-DS
        </Typography>
        <Typography style={{ color: "#3f51b5", fontSize: 40 }}>
          EXCEL-DS
        </Typography>
        <Typography
          variant="h2"
          style={{ color: "#024f46", fontSize: 30, marginBottom: 50 }}
        >
          My Account
        </Typography>
        {!authenticated ? (
          <Box>
            <Button
              onClick={handleGoogleSignInClick}
              variant="contained"
              color="primary"
              startIcon={
                <img style={{ background: "white" }} src="/icons/google.svg" />
              }
            >
              Sign in with Google
            </Button>
          </Box>
        ) : (
          <Box>
            {hasUser ? (
              <UserForm refData={refData} user={user} />
            ) : (
              <UserCreationForm email={email} />
            )}
          </Box>
        )}
      </Box>
      {isLoading ? <Loading /> : null}
    </Container>
  );
};

export default User;

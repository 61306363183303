import React, { useState } from "react";
import { Typography, Slide } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";

const Features = () => {
  const [featureDetails, setFeatureDetails] = useState([
    {
      name: "Capture ALL Data",
      points: ["title", "prices", "availability", "many more..."],
      icon: "all.png",
    },
    {
      name: "Infinite Refreshes",
      points: ["refresh again free", "no quota loss", "updates all data"],
      icon: "infinity.png",
    },
    {
      name: "Indicate Concerns",
      points: ["price changes", "stock changes", "unavailability"],
      icon: "performance.png",
    },
  ]);
  return (
    <Container>
      <Box mt={3} mb={3}>
        <Typography variant="overline" color="textSecondary">
          CEYLON-DS
        </Typography>
        <Typography style={{ color: "#3f51b5", fontSize: 40 }}>
          EXCEL-DS
        </Typography>
        <Typography
          variant="h2"
          style={{ color: "#024f46", fontSize: 30, marginBottom: 50 }}
        >
          Outstanding Features CEYLON DS offers at unbeatable prices
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {featureDetails.map((detail, key) => (
          <Slide
            key={key}
            timeout={1000 - 500 * key}
            style={{ width: "100%" }}
            direction="right"
            in={true}
            mountOnEnter
            unmountOnExit
          >
            <Grid item xs={12} md={4}>
              <Card variant="outlined" sx={{ borderRadius: "16px" }}>
                <img
                  src={"/icons/" + detail.icon}
                  alt="Your Image"
                  style={{
                    width: "80px",
                    display: "block",
                    margin: "0 auto",
                    marginTop: "20px",
                  }}
                />
                <CardHeader
                  style={{
                    textAlign: "center",
                  }}
                  title={detail.name}
                />
                <CardContent>
                  <Box px={1}>
                    <>
                      {detail.points.map((point, key1) => (
                        <Slide
                          key={key1}
                          timeout={1500 + 500 * key1}
                          style={{ width: "100%" }}
                          direction="up"
                          in={true}
                          mountOnEnter
                          unmountOnExit
                        >
                          <Typography variant="subtitle1" color="textSecondary">
                            {point}
                          </Typography>
                        </Slide>
                      ))}
                    </>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Slide>
        ))}
      </Grid>
    </Container>
  );
};

export default Features;

import React, { useState } from "react";
import { Typography, Slide } from "@mui/material";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Container from "@mui/material/Container";

const VLog = () => {
  const [links, setLinks] = useState([
    "https://www.youtube.com/embed/TQWmgrJ24Z4?si=4o1eeR7cyi3OIzC9",
    "https://www.youtube.com/embed/O8Mc5UCTQdY?si=v1wgngRUecRFy5L7",
    "https://www.youtube.com/embed/FBB1i2-yQGQ?si=TFIzHigYUKkb9MrY",
    "https://www.youtube.com/embed/CTEZ8zOgcy4?si=HqX4P3Z3MPVk-N0o",
    "https://www.youtube.com/embed/M5rOkEwYF-k?si=f041e790Wf0Cw-gP",
    "https://www.youtube.com/embed/xfVDyXyrHow?si=hufnXLrVgUAbpnSY",
  ]);
  return (
    <Container>
      <div style={{display:'none'}}>
        <h1>Ceylon DS - Best Affordable Dropshipping Software : Videos</h1>
        <h2>Dropshipping videos</h2>
      </div>
      <Box mt={3} mb={3}>
        <Typography variant="overline" color="textSecondary">
          CEYLON-DS
        </Typography>
        <Typography style={{ color: "#3f51b5", fontSize: 40 }}>
          Drop-Shipping Videos
        </Typography>
      </Box>

      <Grid container spacing={3}>
        {links.map((link, key) => (
          <Slide
            key={key}
            timeout={500 * key}
            style={{ width: "100%" }}
            direction="right"
            in={true}
            mountOnEnter
            unmountOnExit
          >
            <Grid item xs={12} md={4}>
              <Card variant="outlined" sx={{ borderRadius: "16px" }}>
                <CardContent>
                  <Box px={1}>
                    <iframe width={"100%"} src={link} />
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Slide>
        ))}
      </Grid>
    </Container>
  );
};

export default VLog;

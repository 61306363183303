(window as any).dataLayer = (window as any).dataLayer || [];
const trackDownload = () => {
  const dataLayer: any[] = (window as any).dataLayer;
  dataLayer.push({
    formLocation: "image slider",
    event: "download",
  });
};

const trackCurrencyAlter = (to: string) => {
  const dataLayer: any[] = (window as any).dataLayer;
  dataLayer.push({
    formLocation: "packages viewer",
    event: "currency_alter",
    value: to,
  });
};

const trackGoogleSignInClick = () => {
  const dataLayer: any[] = (window as any).dataLayer;
  dataLayer.push({
    formLocation: "account page",
    event: "sign_in_click",
  });
};

const trackGoogleSignInComplete = () => {
  const dataLayer: any[] = (window as any).dataLayer;
  dataLayer.push({
    formLocation: "account page",
    event: "sign_in_complete",
  });
};

const trackUserAccountCreation = () => {
  const dataLayer: any[] = (window as any).dataLayer;
  dataLayer.push({
    formLocation: "acoount page",
    event: "account_create",
  });
};

const trackUserAccountLogout = () => {
  const dataLayer: any[] = (window as any).dataLayer;
  dataLayer.push({
    formLocation: "nav menu",
    event: "logout",
  });
};

const GTM = {
  trackDownload,
  trackCurrencyAlter,
  trackGoogleSignInClick,
  trackGoogleSignInComplete,
  trackUserAccountCreation,
  trackUserAccountLogout,
};

export default GTM;

// src/components/MainPage.tsx
import React, { useEffect, useRef, useState } from "react";
import { Container, Slide } from "@mui/material";
import { Route, Routes } from "react-router-dom";
import NavBar from "../components/NavBar";
import Home from "../components/Home";
import Features from "../components/Features";
import Packages from "../components/Packages";
import Footer from "../components/Footer";
import Box from "@mui/material/Box";
import VLog from "../components/VLog";
import Loading from "../components/Loding";
import User from "../components/User";
import Cookies from "js-cookie";
import Affiliate from "../components/Affiliate";

const MainPage = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [loading, setLoading] = useState(true);
  const [lastScrollPos, setLastScrollPos] = useState(0);
  const [isScrolledToBottom, setIsScrolledToBottom] = useState(false);

  const handleScroll = () => {
    const scrollY = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = document.documentElement.scrollHeight;

    // Check if you're close to the bottom (e.g., within 10 pixels)
    const isCloseToBottom = scrollY + windowHeight >= documentHeight - 50;

    setIsScrolledToBottom(isCloseToBottom);
  };

  useEffect(() => {
    const referralId = new URLSearchParams(window.location.search).get("refId");
    if(referralId && referralId.length===6){
      Cookies.set('referralId',referralId,{expires:1})
    }
    setTimeout(() => {
      setLoading(false);
    }, 1000);
    const handleResize = () => {
      setIsSticky(window.innerWidth >= 768);
    };

    handleResize();

    window.addEventListener("scroll", handleScroll);

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  return (
    <Box sx={{ display: "flex" }}>
      <div className={"wrapper"}>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
        <div>
          <span className="dot"></span>
        </div>
      </div>
      <NavBar isScrolledToBottom={isScrolledToBottom} />

      {loading ? <Loading /> : null}

      <Container
        component="main"
        sx={{
          mt: 16,
          mb: 2,
          flex: 1,
          padding: "0px",
          paddingBottom: "50px",
        }}
      >
        <Box sx={{ minHeight: "120vh" }}>
          <Slide
            timeout={500}
            style={{ width: "100%" }}
            direction="right"
            in={true}
            mountOnEnter
            unmountOnExit
          >
            <div style={{ paddingBottom: "300px" }}>
              <Routes>
                <Route path="/home" element={<Home />} />
                <Route path="/features" element={<Features />} />
                <Route path="/packages" element={<Packages />} />
                <Route path="/vlog" element={<VLog />} />
                <Route path="/account" element={<User />} />
                <Route path="/earn" element={<Affiliate />} />
                <Route path="/" element={<Home />} />
              </Routes>
            </div>
          </Slide>
          <Footer isSticky={isSticky} isScrolledToBottom={isScrolledToBottom} />
        </Box>
      </Container>
    </Box>
  );
};

export default MainPage;

import React, { useEffect, useState } from "react";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Button from "@mui/material/Button";
import GTM from "../util/gtm";

const Home = () => {
  const [height, setHeight] = useState(150);
  const onHeightChange = () => {
    const div1 = document.getElementById("refDiv");
    setHeight(div1 ? div1.clientHeight : 150);
  };



  useEffect(() => {
    setInterval(onHeightChange, 2000);
    window.addEventListener("resize", onHeightChange);

    return () => {
      window.removeEventListener("resize", onHeightChange);
    };
  }, []);
  return (
    
    <div style={{ position: "relative", left: "0" }}>
      <Box mt={3} mb={3}>
        <Typography variant="overline" color="textSecondary">
          CEYLON-DS
        </Typography>
        <Typography style={{ color: "#3f51b5", fontSize: 40 }}>
          EXCEL-DS
        </Typography>
        <Typography
          variant="h2"
          style={{ color: "#024f46", fontSize: 30, marginBottom: 50 }}
        >
          Affordable and Convenient tool for tracking prices, stock of store
          items by a single click
        </Typography>
      </Box>
      <div style={{ position: "absolute", left: "0" }}>
        <Carousel
          autoPlay
          infiniteLoop
          interval={2000} // Set the auto-rotation interval in milliseconds (e.g., 3000ms = 3 seconds)
          showStatus={false}
          showThumbs={false}
        >
          <div id="refDiv">
            <img src="/screens/screen1.jpg" alt="Image 1" />
          </div>
          <div>
            <img src="/screens/screen2.jpg" alt="Image 2" />
          </div>
          <div>
            <img src="/screens/sheet.jpg" alt="Image 3" />
          </div>
        </Carousel>
      </div>
      <div
        className="img-wrapper"
        style={{
          position: "relative",
          textAlign: "center",
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          height: height,
          background: "rgba(0,0,0,0.3)",
          color: "white",
        }}
      >
        <Typography
          style={{
            color: "white",
            WebkitTextStroke: "1px #024f46",
            fontSize: "40px",
            fontWeight: 600,
          }}
          variant="h3"
          gutterBottom
        >
          You can use this exclusive software free for 14 days.
        </Typography>
        <Button
          onClick={GTM.trackDownload}
          href="https://github.com/nilankamanoj/ds-excel-release/releases/download/v1.0.0-app/DS-EXCEL.Setup.1.0.0.exe"
          size="large"
          variant="contained"
          style={{ background: "teal", color: "white" }}
        >
          Download Now
        </Button>
        <div className="pattern0 fireworks fire0">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern1 fireworks fire1">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern2 fireworks fire2">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern3 fireworks fire3">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern4 fireworks fire4">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern5 fireworks fire5">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern6 fireworks fire6">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern7 fireworks fire7">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern8 fireworks fire8">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern9 fireworks fire9">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern10 fireworks fire10">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern11 fireworks fire11">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern12 fireworks fire12">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern13 fireworks fire13">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern14 fireworks fire14">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern15 fireworks fire15">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern16 fireworks fire16">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern17 fireworks fire17">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern18 fireworks fire18">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
        <div className="pattern19 fireworks fire19">
          <div className="ring_1"></div>
          <div className="ring_2"></div>
        </div>
      </div>
      {/*<div*/}
      {/*    style={{*/}
      {/*        width: "50%",*/}
      {/*        position: 'absolute',*/}
      {/*        top: '60%',*/}
      {/*        left: '50%',*/}
      {/*        transform: 'translate(-50%, -50%)',*/}
      {/*        textAlign: 'center',*/}
      {/*        background: 'rgba(0, 0, 0, 0.4)',*/}
      {/*        padding: '20px',*/}
      {/*        fontWeight: '550',*/}
      {/*        color: 'white',*/}
      {/*    }}*/}
      {/*>*/}
      {/*    <Typography variant="h5" gutterBottom>*/}
      {/*        You can use this exclusive software free for 14 days.*/}
      {/*    </Typography>*/}
      {/*    <Button variant="contained" color="primary">*/}
      {/*        Download Now*/}
      {/*    </Button>*/}
      {/*</div>*/}
    </div>
  );
};

export default Home;

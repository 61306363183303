import React, { useState } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import {
  FormControlLabel,
  FormGroup,
  Slide,
  Stack,
  Switch,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import GTM from "../util/gtm";

interface PackageData {
  [packageName: string]: {
    name: string;
    Links: number;
    Price: {
      LKR: number;
      USD: number;
    };
    PerURL: {
      LKR: number;
      USD: number;
    };
    Saves: {
      LKR: number;
      USD: number;
    };
  }[];
}

const Packages = () => {
  const [selectedOp, setSelectedOp] = useState([0, 0, 0, 0, 0, 0]);
  const [currencyType, setCurrencyType] = useState("LKR");

  const handleCurrencyChange = () => {
    const nc = currencyType === "LKR" ? "USD" : "LKR";
    setCurrencyType(nc);
    GTM.trackCurrencyAlter(nc);
  };
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  const [price, setprice] = useState<PackageData>({
    BABY: [
      {
        name: "baby0",
        Links: 100,
        Price: {
          LKR: 900,
          USD: 2.99,
        },
        PerURL: {
          LKR: 9.0,
          USD: 3.0,
        },
        Saves: {
          LKR: 0,
          USD: 0,
        },
      },
      {
        name: "baby1",
        Links: 200,
        Price: {
          LKR: 1600,
          USD: 4.99,
        },
        PerURL: {
          LKR: 8.0,
          USD: 2.5,
        },
        Saves: {
          LKR: 200,
          USD: 0.99,
        },
      },
    ],
    KID: [
      {
        name: "kid0",
        Links: 400,
        Price: {
          LKR: 2600,
          USD: 7.99,
        },
        PerURL: {
          LKR: 6.5,
          USD: 2.0,
        },
        Saves: {
          LKR: 1000,
          USD: 3.97,
        },
      },
      {
        name: "kid1",
        Links: 800,
        Price: {
          LKR: 4900,
          USD: 14.99,
        },
        PerURL: {
          LKR: 6.13,
          USD: 1.87,
        },
        Saves: {
          LKR: 2300,
          USD: 8.93,
        },
      },
    ],
    MEN: [
      {
        name: "men0",
        Links: 1200,
        Price: {
          LKR: 7200,
          USD: 21.99,
        },
        PerURL: {
          LKR: 6.0,
          USD: 1.83,
        },
        Saves: {
          LKR: 3600,
          USD: 13.89,
        },
      },
      {
        name: "men1",
        Links: 2000,
        Price: {
          LKR: 11800,
          USD: 34.99,
        },
        PerURL: {
          LKR: 5.9,
          USD: 1.75,
        },
        Saves: {
          LKR: 6200,
          USD: 24.81,
        },
      },
    ],
    PRO: [
      {
        name: "pro0",
        Links: 3000,
        Price: {
          LKR: 16500,
          USD: 48.99,
        },
        PerURL: {
          LKR: 5.5,
          USD: 1.63,
        },
        Saves: {
          LKR: 10500,
          USD: 40.71,
        },
      },
      {
        name: "pro1",
        Links: 4000,
        Price: {
          LKR: 20200,
          USD: 59.99,
        },
        PerURL: {
          LKR: 5.05,
          USD: 1.5,
        },
        Saves: {
          LKR: 15800,
          USD: 59.61,
        },
      },
    ],
    LEGEND: [
      {
        name: "legend0",
        Links: 6000,
        Price: {
          LKR: 29000,
          USD: 85.99,
        },
        PerURL: {
          LKR: 4.83,
          USD: 1.43,
        },
        Saves: {
          LKR: 25000,
          USD: 93.41,
        },
      },
      {
        name: "legend1",
        Links: 8000,
        Price: {
          LKR: 36800,
          USD: 108.99,
        },
        PerURL: {
          LKR: 4.6,
          USD: 1.36,
        },
        Saves: {
          LKR: 35200,
          USD: 130.21,
        },
      },
    ],
    MASTER: [
      {
        name: "master0",
        Links: 10000,
        Price: {
          LKR: 42200,
          USD: 124.99,
        },
        PerURL: {
          LKR: 4.22,
          USD: 1.25,
        },
        Saves: {
          LKR: 47800,
          USD: 174.01,
        },
      },
      {
        name: "master1",
        Links: 12000,
        Price: {
          LKR: 47900,
          USD: 141.99,
        },
        PerURL: {
          LKR: 3.99,
          USD: 1.18,
        },
        Saves: {
          LKR: 60100,
          USD: 216.81,
        },
      },
    ],
  });

  const [packageDetails, setPackageDetails] = useState([
    {
      name: "BABY",
      icon: "baby.png",
      options: [
        {
          points: ["unlimited refreshes", "14 days free"],
        },
        {
          points: ["unlimited refreshes", "14 days free"],
        },
      ],
    },
    {
      name: "KID",
      icon: "boy.png",
      options: [
        {
          points: ["unlimited refreshes", "14 days free"],
        },
        {
          points: ["unlimited refreshes", "14 days free"],
        },
      ],
    },
    {
      name: "MEN",
      icon: "man.png",
      options: [
        {
          points: ["unlimited refreshes"],
        },
        {
          points: ["unlimited refreshes"],
        },
      ],
    },
    {
      name: "PRO",
      icon: "pro.png",
      options: [
        {
          points: ["unlimited refreshes"],
        },
        {
          points: ["unlimited refreshes"],
        },
      ],
    },
    {
      name: "LEGEND",
      icon: "king.png",
      options: [
        {
          points: ["unlimited refreshes"],
        },
        {
          points: ["unlimited refreshes"],
        },
      ],
    },
    {
      name: "MASTER",
      icon: "master.png",
      options: [
        {
          points: ["unlimited refreshes"],
        },
        {
          points: ["unlimited refreshes"],
        },
      ],
    },
  ]);

  const onChangeOption = (key: number) => {
    console.log(key);
    let tmpOp = [...selectedOp];
    tmpOp[key] = tmpOp[key] === 1 ? 0 : 1;
    setSelectedOp(tmpOp);
  };
  return (
    <Container>
      <div style={{ display: "none" }}>
        <h1>Ceylon DS - Best Affordable Dropshipping Software : Pricing</h1>
        <h2>See all un-beatably low prices here </h2>
      </div>
      <Box mt={3} mb={3}>
        <Typography variant="overline" color="textSecondary">
          CEYLON-DS
        </Typography>
        <Typography style={{ color: "#3f51b5", fontSize: 40 }}>
          EXCEL-DS
        </Typography>
        <Typography
          variant="h2"
          style={{ color: "#024f46", fontSize: 30, marginBottom: 50 }}
        >
          Exclusive Packages
        </Typography>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          currently we are working on integrating payment gateway.
        </Typography>
        <div
          style={{
            borderRadius: "10px",
            border: "1px solid teal",
            padding: "40px",
            display: "inline-block",
            background: "azure",
          }}
        >
          <Typography variant="subtitle1">
            Until then you can purchase/ renew subscriptions contacting us from
          </Typography>
          <div>
            <Box
              style={{ marginLeft: "8px", float: "left" }}
              display="flex"
              alignItems="center"
            >
              <PhoneIcon />
              <span style={{ marginLeft: "2px" }}>+94716454749</span>
            </Box>
            <Box
              style={{ marginLeft: "8px", float: "left" }}
              display="flex"
              alignItems="center"
            >
              <WhatsAppIcon />
              <span style={{ marginLeft: "2px" }}>+94716454749</span>
            </Box>
          </div>
        </div>
      </Box>

      <Grid
        container
        spacing={3}
        style={{
          borderRadius: "10px",
          borderTop: "1px solid teal",
          marginTop: "5px",
        }}
      >
        <Grid item xs={12} md={4}></Grid>
        <Grid item xs={12} md={4}>
          <Box mt={3} mb={3}>
            {/* ... */}

            <Stack
              direction="row"
              spacing={1}
              justifyContent="center"
              alignItems="center"
            >
              <Typography>LKR</Typography>
              <AntSwitch
                checked={currencyType === "USD"}
                onChange={handleCurrencyChange}
                defaultChecked
                inputProps={{ "aria-label": "ant design" }}
              />
              <Typography>USD</Typography>
            </Stack>
          </Box>
        </Grid>
        <Grid item xs={12} md={4}></Grid>
        {packageDetails.map((detail, key) => (
          <Slide
            key={key}
            timeout={500 * key}
            style={{ width: "100%" }}
            direction="right"
            in={true}
            mountOnEnter
            unmountOnExit
          >
            <Grid item xs={12} md={4}>
              <Card variant="outlined" sx={{ borderRadius: "16px" }}>
                <img
                  src={"/icons/" + detail.icon}
                  alt="Your Image"
                  style={{
                    width: "80px",
                    display: "block",
                    margin: "0 auto",
                    marginTop: "20px",
                  }}
                />
                {selectedOp[key] === 1 ? (
                  <Slide
                    timeout={1000}
                    style={{ width: "100%" }}
                    direction="right"
                    in={true}
                    mountOnEnter
                    unmountOnExit
                  >
                    <img
                      src={"/icons/add_" + detail.icon}
                      alt="Your Image"
                      style={{
                        width: "30px",
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  </Slide>
                ) : null}
                <CardHeader
                  style={{
                    textAlign: "center",
                  }}
                  title={detail.name}
                />
                <CardContent>
                  <Box px={1}>
                    <>
                      <Stack
                        display="flex"
                        direction="row"
                        spacing={1}
                        alignSelf="center"
                        alignItems="center"
                      >
                        <Typography>low</Typography>
                        <Switch
                          checked={selectedOp[key] === 1}
                          onChange={() => onChangeOption(key)}
                        />
                        <Typography>high</Typography>
                      </Stack>
                      <Typography variant="h3" gutterBottom>
                        <span>
                          {currencyType === "LKR" ? "Rs " : "$ "}
                          {
                            (price[detail.name][selectedOp[key]].Price as any)[
                              currencyType
                            ]
                          }
                        </span>
                        <span style={{ color: "textSecondary" }}>/ mo</span>
                      </Typography>
                      <Slide
                        timeout={1500}
                        style={{ width: "100%" }}
                        direction="up"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Typography variant="subtitle1" color="textSecondary">
                          {price[detail.name][selectedOp[key]].Links}
                          {" Links"}
                        </Typography>
                      </Slide>
                      {detail.options[selectedOp[key]].points.map(
                        (point, key1) => (
                          <Slide
                            key={key1}
                            timeout={1500 + 500 * key1}
                            style={{ width: "100%" }}
                            direction="up"
                            in={true}
                            mountOnEnter
                            unmountOnExit
                          >
                            <Typography
                              variant="subtitle1"
                              color="textSecondary"
                              className={
                                point.indexOf("free") > -1
                                  ? "animate-charcter"
                                  : ""
                              }
                            >
                              {point}
                            </Typography>
                          </Slide>
                        )
                      )}
                      {(price[detail.name][selectedOp[key]].Saves as any)[
                        currencyType
                      ] !== 0 ? (
                        <Slide
                          timeout={1500}
                          style={{ width: "100%" }}
                          direction="up"
                          in={true}
                          mountOnEnter
                          unmountOnExit
                        >
                          <Typography variant="subtitle1" color="textSecondary">
                            saves {currencyType === "LKR" ? "Rs " : "$ "}
                            {
                              (
                                price[detail.name][selectedOp[key]].Saves as any
                              )[currencyType]
                            }
                          </Typography>
                        </Slide>
                      ) : null}
                      <Slide
                        timeout={1500}
                        style={{ width: "100%" }}
                        direction="up"
                        in={true}
                        mountOnEnter
                        unmountOnExit
                      >
                        <Typography variant="subtitle1" color="textSecondary">
                          {currencyType === "LKR" ? "Rs " : "cents "}
                          {
                            (price[detail.name][selectedOp[key]].PerURL as any)[
                              currencyType
                            ]
                          }
                          {" per url"}
                        </Typography>
                      </Slide>
                    </>
                  </Box>
                  {/*<Button variant="outlined" color="primary">*/}
                  {/*    Select plan*/}
                  {/*</Button>*/}
                  {/*<Box mt={2}>*/}
                  {/*    <Link*/}
                  {/*        href="#"*/}
                  {/*        variant="body1"*/}
                  {/*        underline="hover"*/}
                  {/*        color="primary"*/}
                  {/*    >*/}
                  {/*        Learn more*/}
                  {/*    </Link>*/}
                  {/*</Box>*/}
                </CardContent>
              </Card>
            </Grid>
          </Slide>
        ))}
      </Grid>
    </Container>
  );
};

export default Packages;

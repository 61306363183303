import React from 'react';
import './App.css';
import MainPage from "./pages/MainPage";
import {BrowserRouter} from "react-router-dom";

function App() {
    return (
        <React.StrictMode>
            <BrowserRouter>
                <MainPage/>
            </BrowserRouter>
        </React.StrictMode>
    );
}

export default App;

// Loading.js
import React from 'react';
import {CircularProgress} from '@mui/material';

const Loading = () => {

    return (
        <div className='overlay'>
            <div style={{backgroundImage: `url('/icons/icon_128.png')`, // Set the background image path
                backgroundSize: 'cover', width:128, height:128}}>
                <CircularProgress size={128} color="primary"/>
            </div>
        </div>
    );
};

export default Loading;

import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import ReferralDetails from "./ReferelDetails";

interface User {
  expireTime: string;
  phone: string;
  referralId: string;
  lastPurchaseTime: string;
  totalQuota: string;
  remainingQuota: string;
  email: string;
  key: string;
  refId: string;
}

interface UserFormProps {
  user: User;
  refData:any;
}

const UserForm: React.FC<UserFormProps> = ({ user, refData }) => {
  const [formData, setFormData] = useState<User>(user);

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Email"
          name="email"
          value={formData.email}
          onChange={handleInputChange}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Phone"
          name="phone"
          value={formData.phone}
          onChange={handleInputChange}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Key"
          name="key"
          value={formData.key}
          onChange={handleInputChange}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Remaining Quota"
          name="remainingQuota"
          value={formData.remainingQuota}
          onChange={handleInputChange}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Total Quota"
          name="totalQuota"
          value={formData.totalQuota}
          onChange={handleInputChange}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Last Purchase Time"
          name="lastPurchaseTime"
          value={formData.lastPurchaseTime}
          onChange={handleInputChange}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          label="Expire Time"
          name="expireTime"
          value={formData.expireTime}
          onChange={handleInputChange}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="You Were Refered BY"
          name="referralId"
          value={formData.referralId}
          onChange={handleInputChange}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Typography variant="subtitle1" color="textSecondary" paragraph>
          currently we are working on integrating payment gateway.
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <div
          style={{
            borderRadius: "10px",
            border: "1px solid teal",
            padding: "40px",
            display: "inline-block",
            background: "azure",
          }}
        >
          <Typography variant="subtitle1">
            Until then you can purchase/ renew subscriptions contacting us from
          </Typography>
          <div>
            <Box
              style={{ marginLeft: "8px", float: "left" }}
              display="flex"
              alignItems="center"
            >
              <PhoneIcon />
              <span style={{ marginLeft: "2px" }}>+94716454749</span>
            </Box>
            <Box
              style={{ marginLeft: "8px", float: "left" }}
              display="flex"
              alignItems="center"
            >
              <WhatsAppIcon />
              <span style={{ marginLeft: "2px" }}>+94716454749</span>
            </Box>
          </div>
        </div>
      </Grid>
      <ReferralDetails refId={user.refId} refData={refData} />
    </Grid>
  );
};

export default UserForm;

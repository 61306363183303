import React, { useState, useEffect } from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import { styled } from "@mui/material/styles";
import {
  Button,
  FormControlLabel,
  FormGroup,
  Slide,
  Slider,
  Stack,
  SvgIcon,
  Switch,
  TextField,
} from "@mui/material";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import axios from "axios";
import Cookies from "js-cookie";
import Loading from "./Loding";
import UserForm from "./UserForm";
import UserCreationForm from "./UserCreationForm";
import GTM from "../util/gtm";

interface User {
  refId: string;
  refData: any;
}

const ReferralDetails: React.FC<User> = ({ refId, refData }) => {
  const [marks, setMarks] = useState([
    {
      value: 5,
      label1: "COPPER",
      icon: "copper.png",
      label: 5,
    },
    {
      value: 25,
      label1: "BRONZE",
      icon: "bronze.png",
      label: 25,
    },
    {
      value: 50,
      label1: "SILVER",
      icon: "silver.png",
      label: 50,
    },
    {
      value: 100,
      label1: "GOLD",
      icon: "gold.png",
      label: 100,
    },
    {
      value: 200,
      label1: "PLATINUM",
      icon: "platinum.png",
      label: 200,
    },
    {
      value: 400,
      label1: "DIAMOND",
      icon: "diamond.png",
      label: 400,
    },
  ]);
  useEffect(() => {}, []);

  return (
    <Container>
      <Grid container spacing={2}>
        <Grid item xs={12} margin={1}>
          <Typography variant="h5" color="teal">
            You can now earn from the payments of singnup users from your
            referral URL. So, copy it and share.
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <TextField
            label="Your Referral Link"
            name="email"
            value={"https://ceylonds.com?refId=" + refId}
            fullWidth
            disabled
          />
        </Grid>
        <Grid item xs={12}>
          <Typography>You have {refData.data} currently affiliate signed ups</Typography>
        </Grid>
        <Grid item xs={12}>
          <Box>
            <Slider
              aria-label="Always visible"
              defaultValue={refData.data}
              value={refData.data}
              aria-valuetext="540"
              max={450}
              step={10}
              marks={marks}
            />
          </Box>
          <>
            <Grid container>
              {marks.map((data, key) => (
                <Grid item xs={2}>
                  <Card variant="outlined" sx={{ borderRadius: "5px", margin:"2px" }}>
                    <img
                      className={"element element" + (key + 7)}
                      src={"/icons/" + data.icon}
                      alt="Your Image"
                      style={{
                        width: "40px",
                        display: "block",
                        margin: "0 auto",
                        marginTop: "20px",
                      }}
                    />
                    <CardHeader
                      style={{
                        textAlign: "center",
                      }}
                      title={data.label1}
                    />
                  </Card>
                </Grid>
              ))}
            </Grid>
          </>
        </Grid>
        <Grid item xs={12}>
          <Typography mt={5} variant="h6" color="teal">
            You can view affiliate benefits
          </Typography>
          <Button variant="outlined" href="/earn">
            View Affiliate Benefits
          </Button>
        </Grid>
      </Grid>
    </Container>
  );
};

export default ReferralDetails;

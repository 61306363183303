import React from "react";
import { Container, Grid, Typography, Link, Box } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import RoomIcon from "@mui/icons-material/Room";
import EmailIcon from "@mui/icons-material/Email";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

interface FooterProps {
  isSticky: boolean;
  isScrolledToBottom: boolean;
}

const Footer: React.FC<FooterProps> = ({ isSticky, isScrolledToBottom }) => {
  return (
    <Box
      sx={{
        py: 3,
        px: 2,
        position: isSticky ? "fixed" : "relative",
        bottom: 0,
        left: 0,
        width: "100%",
        background: "teal",
        color: "white",
      }}
    >
      <Container>
        <>
          <footer>
            <Container
              style={{
                bottom: "-500px",
                transition: "bottom 2s ease-in-out",
                display: !isSticky || isScrolledToBottom ? "block" : "none",
              }}
              maxWidth="lg"
            >
              <Grid container spacing={4}>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h6" gutterBottom>
                    <RoomIcon /> Ceylon DS
                  </Typography>
                  <Typography variant="body2">
                    IT solutions provider of ceylon pride.
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h6" gutterBottom>
                    Products
                  </Typography>
                  <Typography variant="body2">
                    <Link href="#" color="inherit">
                      DS EXCEL
                    </Link>
                    <br />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h6" gutterBottom>
                    Useful links
                  </Typography>
                  <Typography variant="body2">
                    <Link href="/Packages" color="inherit">
                      Pricing
                    </Link>
                    <br />
                    <Link href="/Features" color="inherit">
                      Features
                    </Link>
                    <br />
                    <Link href="/VLog" color="inherit">
                      VLog
                    </Link>
                    <br />
                  </Typography>
                </Grid>
                <Grid item xs={12} sm={6} md={3}>
                  <Typography variant="h6" gutterBottom>
                    Contact
                  </Typography>
                  <Typography variant="body2">
                    <RoomIcon /> Gampaha (Western province)
                    <br />
                    <EmailIcon /> ceylonpridegoc@gmail.com
                    <br />
                    <PhoneIcon /> +94716454749
                    <br />
                    <WhatsAppIcon /> +94716454749
                  </Typography>
                </Grid>
              </Grid>
            </Container>

            <Container
              style={{
                display: !isSticky || isScrolledToBottom ? "none" : "block",
              }}
              maxWidth="lg"
            ></Container>

            <Typography
              variant="subtitle1"
              align="center"
              color="textSecondary"
              component="p"
            >
              © 2023 Copyright:
              <Link
                color="inherit"
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                Ceylon DS
              </Link>
            </Typography>
            <Grid container justifyContent="center">
              <Link
                href="https://www.facebook.com/profile.php?id=61551087425083&mibextid=ZbWKwL"
                color="inherit"
              >
                <FacebookIcon />
              </Link>
              <Link
                href="https://www.youtube.com/@ceylonpride8954"
                color="inherit"
              >
                <YouTubeIcon />
              </Link>
            </Grid>
          </footer>
        </>
      </Container>
    </Box>
  );
};

export default Footer;

import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  FormLabel,
  Grid,
  Slide,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import PhoneIcon from "@mui/icons-material/Phone";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import Cookies from "js-cookie";
import Loading from "./Loding";
import GTM from "../util/gtm";

const isLocal = window.location.href.indexOf("localhost") > -1;

interface UFProps {
  email: string;
}

const UserCreationForm: React.FC<UFProps> = ({ email }) => {
  const [phone, setPhone] = useState("");
  const ref = Cookies.get("referralId");
  const [referralId, setReferralId] = useState(
    ref && ref.length === 6 ? ref : "NONE"
  );
  const [error, setError] = useState("");
  const [quota, setQuoata] = useState(0);
  const [loading, setIsLoading] = useState(false);
  const [packages, setPackages] = useState([
    {
      name: "BABY",
      icon: "baby.png",

      selected: false,
      count: 100,
      high: false,
    },
    {
      name: "BABY",
      icon: "baby.png",

      selected: false,
      count: 200,
      high: true,
    },
    {
      name: "KID",
      icon: "boy.png",
      selected: false,
      count: 400,
      high: false,
    },
    {
      name: "KID",
      icon: "boy.png",
      selected: false,
      count: 800,
      high: true,
    },
  ]);
  const createUser = async () => {
    const jwt = Cookies.get("jwt");

    if (jwt) {
      const url = isLocal
        ? "http://127.0.0.1:5001/ds-excel-app/us-central1/users"
        : "https://users-3xry7nzu2q-uc.a.run.app";
      fetch(url, {
        method: "POST",
        body: JSON.stringify({
          isLocal,
          action: "CREATE",
          jwt,
          phone,
          quota,
          referralId,
        }),
      })
        .then((result) => {
          return result.json();
        })
        .then((data) => {
          if (data.email) {
            GTM.trackUserAccountCreation();
            window.location.reload();
          }
          if (data.error) {
            setIsLoading(false);
            setError(data.error);
          }
        })
        .catch((error) => {
          console.log(error);
          setIsLoading(false);
        });
    }
  };

  const onUpdatePhone = (e: any) => {
    setPhone(e.target.value);
    const phoneNumberPattern = /^\d{10}$/;
  };

  const onUpdateRefId = (e: any) => {
    setReferralId(e.target.value);
  };
  const validate = () => {
    setError("");
    const phoneNumberPattern = /^\d{10}$/;
    if (!phoneNumberPattern.test(phone)) {
      setError("Invalid Phone Number!");
      return false;
    }
    if ([100, 200, 400, 800].indexOf(quota) === -1) {
      setError("Please Select Quota!");
      return false;
    }
    if (referralId !== "NONE" && referralId.length != 6) {
      setError("Invalid Referral ID!");
      return false;
    }
    return true;
  };
  const handleSubmit = () => {
    const valid = validate();
    console.log({ quota, referralId, phone, valid });
    if (valid) {
      setIsLoading(true);
      createUser();
    }
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <TextField
          label="Email"
          name="email"
          value={email}
          fullWidth
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          label="Phone"
          name="phone"
          value={phone}
          onChange={onUpdatePhone}
          fullWidth
        />
      </Grid>
      <Grid item xs={12}>
        <FormLabel>Select Trial Package</FormLabel>
      </Grid>
      {packages.map((detail, key) => (
        <Slide
          key={key}
          timeout={500 * key}
          style={{ width: "100%" }}
          direction="right"
          in={true}
          mountOnEnter
          unmountOnExit
        >
          <Grid item xs={12} md={3}>
            <Card
              onClick={() => setQuoata(detail.count)}
              variant="outlined"
              sx={{
                borderRadius: "16px",
                border:
                  detail.count === quota ? "2px solid teal" : "2px solid ash",
              }}
            >
              <img
                src={"/icons/" + detail.icon}
                alt="Your Image"
                style={{
                  width: "40px",
                  display: "block",
                  margin: "0 auto",
                  marginTop: "20px",
                }}
              />
              <img
                src={"/icons/add_" + detail.icon}
                alt="Your Image"
                style={{
                  width: "20px",
                  display: detail.high ? "block" : "none",
                  margin: "0 auto",
                  marginTop: "20px",
                }}
              />
              <div
                style={{
                  height: "20px",
                  display: detail.high ? "none" : "block",
                  margin: "0 auto",
                  marginTop: "20px",
                }}
              />

              <CardHeader
                style={{
                  textAlign: "center",
                }}
                title={detail.name + " : " + (detail.high ? "HIGH" : "LOW")}
              />
              <CardContent>
                <Box px={1}>
                  <Typography align="center" variant="h6" gutterBottom>
                    {detail.count}
                  </Typography>
                </Box>
              </CardContent>
            </Card>
          </Grid>
        </Slide>
      ))}
      <Grid item xs={12}>
        <FormLabel>
          Dont worry, this is free for 14 days! you can downgrade/ upgrade when
          renewing
        </FormLabel>
      </Grid>
      <br></br>
      <Grid item xs={10}>
        <TextField
          label="Referral ID (You were refered by)"
          name="referralId"
          value={referralId}
          onChange={onUpdateRefId}
          fullWidth
        />
      </Grid>
      <Grid item xs={2}>
        <Button
          variant="contained"
          color="warning"
          onClick={() => setReferralId("NONE")}
        >
          I was not refered
        </Button>
      </Grid>
      <Grid item xs={12}>
        <Typography color={"red"}>{error}</Typography>
      </Grid>
      <Grid item xs={12}>
        <Button
          fullWidth
          variant="contained"
          color="primary"
          onClick={handleSubmit}
        >
          Submit
        </Button>
      </Grid>
      {loading ? <Loading /> : null}
    </Grid>
  );
};

export default UserCreationForm;
